import Typography from 'components/molecules/typography'
import { noDataPlaceholder, unitPrefixes, unitSuffixes } from 'config/data'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { formatDataTestE2eAttr, formatDate } from 'utilities/format'
import { v4 as uuidV4 } from 'uuid'

const StyledTitle = styled(Typography)`
  display: block;
  padding-bottom: ${(props) => props.theme.sizings.lvl0};
`
const StyledValue = styled(Typography)`
  margin-left: 0;
  &:first-letter {
    text-transform: uppercase;
  }
`
/**
 * Check if string does not matches a date string
 */
const looksLikeADateString = (str) => /^\d{4}-\d{2}-\d{2}/.test(str)

/**
 * Used for displaying label-value pairs. This is default way to display static data for UCC.
 *
 * To show this in a combined overview please
 * use [StaticValuesCollection](/#/Molecules/StaticValuesCollection).
 */
// eslint-disable-next-line space-before-function-paren
function Definition({
  className,
  title,
  value,
  type,
  id,
  unit,
  options,
  titleComponent,
}) {
  const { t } = useTranslation()
  const labelId = uuidV4()

  if (type === 'hidden') {
    return false
  }

  function getFormattedValue() {
    switch (type) {
      case 'date': {
        return formatDate(value)
      }
      case 'select': {
        if (value) {
          if (options.find((option) => option.value.toString() === value)) {
            return options.find((option) => option.value.toString() === value)
              .label
          }
          return value
        }
        return noDataPlaceholder
      }
      case 'checkbox': {
        if (value === true) {
          return t('yes')
        }
        return t('no')
      }
      default:
      case 'number':
      case null:
      case undefined: {
        if (value === undefined || value === '' || value === null) {
          return noDataPlaceholder
        }
        const valueCastAsString = String(value)
        return looksLikeADateString(valueCastAsString)
          ? valueCastAsString
          : t(value) // @todo why do values need to be translated?
      }
    }
  }

  function getCompiledValue() {
    const val = getFormattedValue()
    if (val === noDataPlaceholder) {
      return val
    }
    if (unit && unitPrefixes[unit]) {
      return `${unitPrefixes[unit]} ${val}`
    }
    if (unit) {
      return `${val} ${unitSuffixes[unit] || unit}`
    }
    return val
  }

  return (
    <div
      data-test-e2e={formatDataTestE2eAttr('definition', title)}
      className={className}
    >
      <StyledTitle
        type="InlineBodyText"
        id={id || labelId}
        data-test-e2e={formatDataTestE2eAttr('definition-title', title)}
      >
        {title || noDataPlaceholder}
        {titleComponent}
      </StyledTitle>
      <StyledValue
        type="InlineBodyTextBold"
        aria-labelledby={id || labelId}
        data-test-e2e={formatDataTestE2eAttr('definition-value', title)}
      >
        {getCompiledValue()}
      </StyledValue>
    </div>
  )
}

Definition.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleComponent: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  type: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
  unit: PropTypes.string,
}

Definition.defaultProps = {
  className: null,
  title: null,
  titleComponent: undefined,
  value: noDataPlaceholder,
  id: undefined,
  unit: undefined,
  type: undefined,
  options: [],
}

export default Definition
