export const ADS = '/advertisements'
export const CAR_ENTRY = '/entry'
export const CAR_ENRICHMENT = '/car-file-enrichment'
export const CAR_FILE = '/car'
export const B2B_CAR_FILE = '/b2b-car-file'
export const CAR_SHARE_CAR_FILE = '/carshare'
export const DASHBOARD = '/'
export const FORGOT_PASSWORD = '/forgot-password'
export const LOGIN = '/login'
export const RDW_STOCK = '/rdw-stock'
export const RDW_CAR_TRANSFER = '/rdw-car-transfer'
export const RDW_TRANSFER_RECORD_CAR = `${RDW_CAR_TRANSFER}/record-car`
export const RDW_TRANSFERS_RETRACT_CAR = `${RDW_CAR_TRANSFER}/retract-car`
export const RDW_TRANSFER_CHECK_CAR = `${RDW_CAR_TRANSFER}/check-car`
export const RDW_TRANSFERS_SENT_CARS = `${RDW_CAR_TRANSFER}/sent-cars`
export const RDW_TRANSFERS_RECEIVED_CARS = `${RDW_CAR_TRANSFER}/received-cars`
export const RDW_REQUEST_DOCUMENT_NUMBER = '/rdw-request-document-number'
export const RESET_PASSWORD = '/reset-password'
export const SEARCH_RESULTS = '/search'
export const SETTINGS = '/settings'
export const STOCK = '/stock'
export const B2B_TRADE_PLATFORM = '/b2b-trade-platform'
export const LOG = '/log'
export const NEW_CAR_FILE_VIA_CAR_COLLECT = '/new-car-file-via-car-collect'
export const CHECKLISTS = '/flexflows'

// Administration + tabs
export const ADMINISTRATION = '/administration'
export const ADMINISTRATION_TRANSACTIONS = `${ADMINISTRATION}/transactions`
export const ADMINISTRATION_DIRECT_DEBIT_FILES = `${ADMINISTRATION}/direct-debit-files`
export const ADMINISTRATION_CSP_TRANSACTIONS = `${ADMINISTRATION}/csp-transactions`

export const VALUATION = '/valuation'
export const CAR_BPM_DECLARATION = '/bpm'
export const CAMERA = '/camera'
export const MARKTVIEW = '/marketview'

// CSP + nested routes
export const CSP = '/car-service-plan'
export const CSP_SALES_FLOW = `${CSP}/sales-flow`
export const CSP_NO_LICENSE_PLATE = `${CSP}/no-license-plate`
export const CSP_CONTRACT_FILE = `${CSP}/contract`
export const CSP_DEMO_SALES_FLOW = `${CSP}/sales-flow-demo`
export const CSP_CONTRACT_TABS_OVERVIEW = '/contracts-overview' // This is just the path, if you need the absolute url, use `CSP_CONTRACTS_OVERVIEW`
export const CSP_CONTRACT_TABS_EXTENSION_REQUESTS = '/extension-requests' // This is just the path, if you need the absolute url, use `CSP_EXTENSION_REQUESTS`
export const CSP_CONTRACTS_OVERVIEW = `${CSP}${CSP_CONTRACT_TABS_OVERVIEW}`
export const CSP_EXTENSION_REQUESTS = `${CSP}${CSP_CONTRACT_TABS_EXTENSION_REQUESTS}`
export const CSP_DEALER_SITE_WIDGET_CTA = '/csp-dealer-site-widget'
export const CSP_DEALER_SITE_WIDGET_FORM = '/csp-dealer-site-widget-form'

// Management info page
export const MANAGEMENT_INFO = '/management-info'
export const MANAGEMENT_INFO_STAND_TIME = '/statijd'
export const MANAGEMENT_INFO_TAXATION = '/taxatie'

// used by car-version-search component
export const LICENSE_PLATE_SEARCH_BY_VIN = '/vin'
export const LICENSE_PLATE_SEARCH_BY_LICENSE = '/license'
export const LICENSE_PLATE_SEARCH_WITHOUT_LICENSE = '/nolicenseplate'
export const DELPHI_AI_CHATBOT = '/remarketing-assistant'
