import React from 'react'
import styled from 'styled-components/macro'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import MediaImage from 'components/molecules/media-image'
import TextLink from 'components/atoms/text-link'
import { getFileExtension } from 'utilities/utils'
import { media } from 'utilities/styled'
import Typography from 'components/molecules/typography'
import { IMAGE_FILE_EXTENSIONS } from 'components/organisms/media-form'

function getDocImgSrc(fileName) {
  const fileExtension = getFileExtension(fileName)

  return `/images/file-${fileExtension}.svg`
}

const ImagesContainer = styled.div`
  display: grid;
  margin-bottom: ${({ theme }) => theme.sizings.lvl5};
  row-gap: ${({ theme }) => theme.sizings.lvl4};
  column-gap: ${({ theme }) => theme.sizings.lvl3};
  grid-template-columns: repeat(2, 1fr);
  user-select: none;
  width: 100%;

  ${media.tablet`
    display: grid;
    row-gap: ${({ theme }) => theme.sizings.lvl4};
    column-gap: ${({ theme }) => theme.sizings.lvl3};
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.tv`
    grid-template-columns: repeat(6, 1fr);
  `}

  ${({ fullWidth }) =>
    fullWidth ? 'grid-template-columns: 1fr !important;' : undefined}
`

const MediaItem = styled.div`
  display: block;
`

const MediaItemTitle = styled(Typography)`
  display: block;
  margin-top: 12px;
`

const ViewLink = styled(TextLink)`
  margin-top: 4px;
`

const SortableItem = SortableElement(
  ({
    item,
    clickDisabled,
    onSelect,
    selectedImageIds,
    sorting,
    toggleSelection,
    onClick,
    exclusiveSelect,
    showLabelOnImage,
    aspectRatio = 4 / 3,
  }) => {
    const extension = getFileExtension(item.src)
    const showAsImage =
      item.type === 'image' ||
      (item.type === 'document' &&
        IMAGE_FILE_EXTENSIONS.includes(`.${extension}`)) ||
      typeof item.type === 'undefined'
    const src = showAsImage ? item.src : getDocImgSrc(item.src)
    let showLabel = Boolean(item?.name)
    if (item.type === 'image') {
      showLabel = showLabelOnImage
    }
    return (
      <MediaItem data-test-e2e="media-item">
        <MediaImage
          onClick={onClick}
          disabled={clickDisabled}
          alt={item.name}
          ratio={aspectRatio}
          selected={selectedImageIds.indexOf(item.id) !== -1}
          secondarySelected={sorting}
          onSelect={onSelect ? () => toggleSelection(item.id) : null}
          src={src}
          exclusiveSelect={exclusiveSelect}
        />
        {showLabel && (
          <MediaItemTitle type="InlineBodyText">{item.name}</MediaItemTitle>
        )}
      </MediaItem>
    )
  },
)

export const SortableGrid = SortableContainer(
  ({
    disabled,
    clickDisabled,
    onSelect,
    items,
    onView,
    selectedImageIds,
    sortingImageId,
    t,
    toggleSelection,
    exclusiveSelect,
    showLabelsOnImages,
    imageAspectRatio,
    ...restProps
  }) => {
    return (
      <ImagesContainer {...restProps}>
        {items.map((item, index) =>
          item.type === 'document' ? (
            <div key={index.toString()}>
              <SortableItem
                disabled={disabled}
                clickDisabled={clickDisabled}
                index={index}
                item={item}
                onSelect={onSelect}
                selectedImageIds={selectedImageIds}
                sorting={index === sortingImageId}
                toggleSelection={toggleSelection}
                value={item}
                exclusiveSelect={exclusiveSelect}
                aspectRatio={imageAspectRatio}
                data-test-e2e="sortable-item"
              />
              {item.type === 'document' && (
                <ViewLink onClick={() => onView(item.src)} text={t('view')} />
              )}
            </div>
          ) : (
            <SortableItem
              onClick={!onSelect ? () => onView(item.src) : null}
              disabled={disabled}
              clickDisabled={clickDisabled}
              index={index}
              item={item}
              key={index.toString()}
              onSelect={onSelect}
              selectedImageIds={selectedImageIds}
              sorting={index === sortingImageId}
              toggleSelection={toggleSelection}
              showLabelOnImage={showLabelsOnImages}
              value={item}
              exclusiveSelect={exclusiveSelect}
              aspectRatio={imageAspectRatio}
              data-test-e2e="sortable-item"
            />
          ),
        )}
      </ImagesContainer>
    )
  },
)
