/* eslint-disable indent, react/jsx-indent */ // TODO: Remove once linting setup is fixed

import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { formatMileage, getMonthNameByIndex } from 'utilities/utils'

import {
  CarFileStatusEnumLowerCase,
  CarFileStatusEnumUpperCase,
} from 'config/enums'

import Definition from 'components/atoms/definition'
import GalleryImage from 'components/atoms/gallery-image'
import Icon from 'components/atoms/icon'
import Text from 'components/atoms/text'
import Tooltip from 'components/atoms/tooltip'
import CarBrandAndModel from 'components/molecules/car-brand-and-model'
import CarIdentityPlate from 'components/molecules/car-identity-plate'
import CarFileCardBids, {
  CarFileCardBidsLayoutType,
  defaultProps as carFileCardBidsDefaultProps,
  propTypes as carFileCardBidsPropTypes,
} from 'components/molecules/carfile-card-bids'
import CarFileCardContextMenu from 'components/molecules/carfile-card-context-menu-container'
import StandDuration from 'components/molecules/stand-duration'

const Container = styled.div`
  box-shadow: ${({ theme }) => theme.shadows.bottom};
  background-color: ${({ theme }) => theme.colors.brandGolf};
  display: flex;
  flex-direction: column;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > * {
    margin: 0 ${({ theme }) => theme.sizings.lvl2};
  }
`

const StyledInnerContainer = styled(Link)`
  // flex: 1 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  padding-bottom: ${({ theme }) => theme.sizings.lvl2};

  & > * {
    flex: 0 0 auto;

    &:last-child {
      justify-self: flex-end;
    }
  }
`

const StyledInnerContainerHeader = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  & > div > span:nth-child(1),
  & > div > span:nth-child(2) {
    padding-right: ${({ theme }) => theme.sizeByFactor(16)};
  }
`

const ActionsContainer = styled.div`
  margin: 0 ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl2};

  & > *:not(:first-child) {
    margin-top: ${({ theme }) => theme.sizings.lvl1};
  }
`

const StyledSummary = styled(Link)`
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.brandFoxtrot};
  margin-bottom: ${({ theme }) => theme.sizings.lvl2};
  margin-top: auto;
`

const StyledLabeledTexts = styled.div`
  display: flex;
`

const StyledDefinition = styled(Definition)`
  border-left: 1px solid ${({ theme }) => theme.colors.brandDelta};
  padding: 0 ${({ theme }) => theme.sizings.lvl2};
  margin: ${({ theme }) => theme.sizings.lvl2} 0;

  &:first-child {
    border-left: 0;
  }
`

const StyledMeta = styled.div`
  align-items: center;
  display: flex;
  padding-top: ${({ theme }) => theme.sizings.lvl2};
  margin-bottom: auto;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.sizings.lvl0};
`

const StyledMetaText = styled(Text)`
  white-space: nowrap;
  flex-shrink: 0;
`

const StyledStandDuration = styled(StandDuration)`
  padding: ${({ theme }) => theme.sizings.lvl2};
  padding-top: ${({ theme }) => theme.sizings.lvl1};
`

const StyledExpected = styled(Text)`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.brandDelta};
  display: flex;
  padding: ${({ theme }) => theme.sizings.lvl2};
`

const OwnerContainer = styled.div`
  display: flex;
  text-transform: capitalize;

  & > *:first-child {
    margin-right: 0.5em;
  }
`
const TopBlock = styled.div`
  position: relative;
  margin: 0;
`

const CarFileLabels = styled.ul`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: row-reverse;

  > li {
    display: block;
    height: 2em;
    width: 2em;
    border-radius: 50%;
    padding: 0.25em;
    background-color: #fff;
    margin-left: 10px;
  }
`

const StyledCarIdentityPlate = styled(CarIdentityPlate)`
  position: absolute;
  top: 0;
  right: 0;
  margin-left: auto;
  flex-shrink: 0;
`

const StyledCarFileCardBids = styled(CarFileCardBids)`
  padding: 0 ${({ theme }) => theme.sizings.lvl2}
    ${({ theme }) => theme.sizings.lvl2};
`

const StyledCarFileCardBidsSummary = styled(CarFileCardBids)`
  padding: ${({ theme }) => theme.sizings.lvl2};
  padding-top: ${({ theme }) => theme.sizings.lvl1};
`

function CarfileCard({
  carId,
  actions,
  appraisal,
  brand,
  createdExternally,
  expected,
  image,
  license,
  vin,
  km,
  link,
  location,
  margin,
  model,
  month,
  owner,
  part1,
  part2,
  reportingCode,
  type,
  salesView,
  hideSummary,
  sellingPrice,
  status,
  staytime,
  year,
  publishedOnline,
  cleaningOrderIsIssued,
  hasRemarks,
  isCarShare,
  carShareBids,
  ...restProps
}) {
  const { t } = useTranslation()
  // const { dealerName: carLocation } = useDealerLocationName()

  const carLabel = useMemo(() => {
    // When it's a carShare car, we want to display a static translation string
    if (isCarShare) {
      return t('carShare.carFileStockOverviewLabel')
    }

    // When the passed 'status' is a string in the CarFileStatusEnum (V3 / coming from the backend), then display the translated string for this status
    if (Object.values(CarFileStatusEnumLowerCase).includes(status)) {
      return t(`carFileStatussesV3.${status}`)
    }

    // When the above logic doesn't match, display the string that either has been given to this component or null, which shows no label in the Gallery Image
    return status
  }, [t, isCarShare, status])

  return (
    <Container {...restProps}>
      <StyledWrapper>
        <TopBlock>
          <Link to={link}>
            <GalleryImage
              src={image}
              alt="Auto title"
              label={carLabel}
              ratio={16 / 9}
              blurredBackground
            />
          </Link>

          <CarFileLabels>
            {!isCarShare && (
              <>
                <CarFileCardContextMenu
                  carId={carId}
                  reportingCode={reportingCode}
                  mileage={km}
                  license={license}
                  isCarShare={isCarShare}
                />
                {cleaningOrderIsIssued && (
                  <li>
                    <Tooltip message={t('carFileCleaningOrderIsIssued')}>
                      <Icon
                        type="cleaning"
                        color="actionsStandard"
                        width="22"
                        height="22"
                      />
                    </Tooltip>
                  </li>
                )}
                {publishedOnline && (
                  <li>
                    <Tooltip message={t('carFilePublishedOnline')}>
                      <Icon
                        color="actionsStandard"
                        size="lg"
                        type="carFilePublishedOnline"
                      />
                    </Tooltip>
                  </li>
                )}
                {hasRemarks && (
                  <li>
                    <Tooltip message={t('carFileHasRemarks')}>
                      <Icon
                        color="actionsStandard"
                        size="lg"
                        type="carFileHasRemarks"
                      />
                    </Tooltip>
                  </li>
                )}
                {/* Only show the createdExternally icon shen createdExternally === 1
              it can also be 2 but this means that it was altered within UCC after
              importing it from the external source
              */}
                {createdExternally === 1 && (
                  <li>
                    <Tooltip message={t('carFileCreatedExternally')}>
                      <Icon
                        color="actionsStandard"
                        size="lg"
                        type="carFileCreatedExternally"
                      />
                    </Tooltip>
                  </li>
                )}
              </>
            )}
          </CarFileLabels>
        </TopBlock>
        <StyledInnerContainer to={link}>
          <StyledInnerContainerHeader>
            <CarBrandAndModel
              carBrand={brand}
              carModel={model}
              carType={type}
              size="small"
              data-test-e2e="car-brand-and-model"
            />
            <StyledCarIdentityPlate
              carFileId={carId}
              licensePlate={license}
              vin={vin}
              size="small"
            />
          </StyledInnerContainerHeader>
          <StyledMeta>
            {typeof km !== 'undefined' && (month || year) && (
              <>
                <StyledMetaText type="body" text={formatMileage(km)} />
                <StyledMetaText type="bodyLight" text="|" />
                <StyledMetaText
                  type="body"
                  text={`${t(getMonthNameByIndex(month))} ${year}`}
                />
              </>
            )}
            {typeof km !== 'undefined' && !(month || year) && (
              <Text type="body" text={formatMileage(km)} />
            )}
            {location && (
              <>
                <StyledMetaText type="bodyLight" text="|" />
                <StyledMetaText type="body" text={location} />
              </>
            )}
            {owner && (
              <OwnerContainer>
                <StyledMetaText type="body" text={`${t('owner')}: `} />
                <StyledMetaText type="body" weight={500} text={t(owner)} />
              </OwnerContainer>
            )}
          </StyledMeta>
        </StyledInnerContainer>
        {!hideSummary && (
          <StyledSummary to={link}>
            <StyledLabeledTexts>
              {status !==
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.lopend
                ] && (
                <StyledDefinition
                  title={t('sellingPrice')}
                  value={
                    sellingPrice !== undefined && sellingPrice !== null
                      ? sellingPrice
                      : t('pricesMissing')
                  }
                  type={
                    sellingPrice !== undefined && sellingPrice !== null
                      ? 'number'
                      : undefined
                  }
                  unit={
                    sellingPrice !== undefined && sellingPrice !== null
                      ? 'currency_euro'
                      : undefined
                  }
                />
              )}
              {status ===
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.lopend
                ] && (
                <StyledDefinition
                  title={t('appraisal')}
                  value={
                    appraisal !== undefined && appraisal !== null
                      ? appraisal
                      : t('pricesMissing')
                  }
                  type={
                    appraisal !== undefined && appraisal !== null
                      ? 'number'
                      : undefined
                  }
                  unit={
                    appraisal !== undefined && appraisal !== null
                      ? 'currency_euro'
                      : undefined
                  }
                />
              )}
              {!salesView && !!margin && (
                <StyledDefinition title={t('margin')} value={margin} />
              )}
              {part1 && (
                <>
                  <StyledDefinition title={t('part1')} value={part1} />
                  <StyledDefinition title={t('part2')} value={part2} />
                </>
              )}
            </StyledLabeledTexts>
            {expected && !salesView && (
              <StyledExpected
                text={`${t('inStockExpected')}: ${moment(expected).format(
                  'DD-MM-YYYY',
                )}`}
              />
            )}
            {typeof staytime !== 'undefined' &&
              !salesView &&
              [
                CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.binnen],
                CarFileStatusEnumLowerCase[CarFileStatusEnumUpperCase.verkocht],
                CarFileStatusEnumLowerCase[
                  CarFileStatusEnumUpperCase.afgeleverd
                ],
              ].includes(status) && (
                <StyledStandDuration title={t('staytime')} days={staytime} />
              )}
            {!isCarShare && !!carShareBids?.requested && (
              <StyledCarFileCardBidsSummary
                carShareBids={carShareBids}
                layoutType={CarFileCardBidsLayoutType.VERTICAL}
                breakDeadline={false}
              />
            )}
          </StyledSummary>
        )}
      </StyledWrapper>
      {isCarShare && !!carShareBids?.deadline_formatted && (
        <StyledCarFileCardBids
          link={link}
          carShareBids={carShareBids}
          layoutType={CarFileCardBidsLayoutType.VERTICAL}
          breakDeadline={false}
        />
      )}
      {actions && <ActionsContainer>{actions}</ActionsContainer>}
    </Container>
  )
}

CarfileCard.propTypes = {
  actions: PropTypes.object,
  appraisal: PropTypes.number,
  brand: PropTypes.string,
  createdExternally: PropTypes.number,
  expected: PropTypes.string,
  carId: PropTypes.string.isRequired,
  image: PropTypes.string,
  license: PropTypes.string,
  vin: PropTypes.string,
  link: PropTypes.string.isRequired,
  km: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  month: PropTypes.string,
  margin: PropTypes.number,
  model: PropTypes.string,
  owner: PropTypes.string,
  part1: PropTypes.string,
  part2: PropTypes.string,
  reportingCode: PropTypes.string, // meldcode
  type: PropTypes.string,
  salesView: PropTypes.bool,
  location: PropTypes.string,
  /**
   * optionally hide the summary block containing pricing info and standing/stay-time
   */
  hideSummary: PropTypes.bool,
  sellingPrice: PropTypes.number,
  status: PropTypes.string,
  staytime: PropTypes.number,
  year: PropTypes.number,
  publishedOnline: PropTypes.bool,
  cleaningOrderIsIssued: PropTypes.bool,
  hasRemarks: PropTypes.bool,
  isCarShare: PropTypes.bool,
  ...carFileCardBidsPropTypes,
}

CarfileCard.defaultProps = {
  actions: null,
  appraisal: null,
  brand: '-',
  createdExternally: 0,
  expected: null,
  image: null,
  license: undefined,
  vin: undefined,
  location: undefined,
  km: 0,
  margin: null,
  month: null,
  model: '-',
  owner: null,
  part1: null,
  part2: null,
  reportingCode: null, // meldcode
  type: null,
  salesView: false,
  hideSummary: false,
  sellingPrice: null,
  status: null,
  staytime: null,
  year: null,
  publishedOnline: false,
  cleaningOrderIsIssued: false,
  hasRemarks: false,
  isCarShare: false,
  ...carFileCardBidsDefaultProps,
}

export default CarfileCard
