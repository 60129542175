import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import MediaForm from 'components/organisms/media-form'

function BackgroundImageForm({
  addBackgroundImage,
  backgroundImage,
  getBackgroundImage,
  locationId,
}) {
  const { t } = useTranslation()
  const loading = backgroundImage && backgroundImage.loading
  const photo =
    backgroundImage &&
    backgroundImage[locationId] &&
    backgroundImage[locationId].data

  useEffect(() => {
    getBackgroundImage({ id: locationId })
  }, [getBackgroundImage, locationId])

  function handleChange(image) {
    addBackgroundImage({
      id: locationId,
      image,
    })
  }

  return (
    <>
      {!loading && photo && (
        <MediaForm
          level="standard"
          items={[
            {
              name: t('uploadYourBackgroundImage'),
              type: 'image',
              src: photo,
            },
          ]}
          onUpload={handleChange}
          title={t('uploadYourBackgroundImage')}
          type="images"
          uploadButtonText={t('uploadPhoto')}
          single
          fullWidth
          imageAspectRatio={3.38 / 1}
        />
      )}
    </>
  )
}

BackgroundImageForm.propTypes = {
  addBackgroundImage: PropTypes.func.isRequired,
  backgroundImage: PropTypes.object,
  getBackgroundImage: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
}

BackgroundImageForm.defaultProps = {
  backgroundImage: null,
}

export default BackgroundImageForm
