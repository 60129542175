import Button from 'components/atoms/button'
import * as ROUTES from 'config/routes'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const StyledButton = styled(Button)`
  padding: 0;
  width: ${({ theme }) => theme.sizeByFactor(5)};
`

const StyledImg = styled.img`
  height: ${({ theme }) => theme.sizeByFactor(3.5)};
  width: ${({ theme }) => theme.sizeByFactor(3.5)};
  vertical-align: middle; // aligns the image with the white circular hover effect
`

const DelphiAIChatBotButton = ({ className, ...restProps }) => {
  const { t } = useTranslation()

  return (
    <StyledButton
      className={className}
      level="minimal"
      to={ROUTES.DELPHI_AI_CHATBOT}
      data-test-e2e="button-delphi-ai"
      noPadding
      {...restProps}
    >
      <StyledImg
        src="/images/icon-chat-avatar.png"
        alt={t('openDelphiAIChatBot')}
      />
    </StyledButton>
  )
}

DelphiAIChatBotButton.propTypes = {
  className: PropTypes.string,
}

DelphiAIChatBotButton.defaultProps = {
  className: '',
}

export default DelphiAIChatBotButton
