import FieldNotMappedTooltip from 'components/molecules/field-not-mapped-tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'

export const CarBrandAndModelLayoutTypes = {
  COLUMN: 'column',
  MODEL_TYPE_INLINED: 'model-type-inlined',
  INLINED: 'inlined',
}

const CarBrandAndModelContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  align-items: end;
  grid-row-gap: ${({ theme, $size }) =>
    $size === 'large' ? theme.sizings.lvl1 : theme.sizings.lvl0};
  grid-column-gap: ${({ theme }) => theme.sizeByFactor(0.5)};

  ${({ $layoutType }) => {
    if ($layoutType === CarBrandAndModelLayoutTypes.MODEL_TYPE_INLINED) {
      return `
      grid-template-columns: minmax(0,max-content) minmax(0,max-content);
      grid-template-areas:
      'carBrand carBrand'
      'carModel carType';
    `
    }
    if ($layoutType === CarBrandAndModelLayoutTypes.INLINED) {
      return `
      grid-template-columns: minmax(0,max-content) minmax(0,max-content) minmax(0,max-content);
      grid-template-areas:
      'carBrand carModel carType';
    `
    }
    return `
    grid-template-areas:
      'carBrand'
      'carModel'
      'carType';`
  }}
  color: ${({ theme }) => theme.colors.text};
`

const SharedLineStyles = css`
  display: block;
  font-family: ${({ theme }) => theme.font};
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`

const CarBrandLine = styled.span`
  ${SharedLineStyles}
  grid-area: carBrand;
  line-height: 1.1;
  font-weight: 300;

  ${({ theme, $size }) => {
    /**
     * Using switch statement to make this component
     * easier to extend.
     */
    switch ($size) {
      case 'large':
        return `
      font-size: ${theme.sizeByFactor(2.5)};
      text-transform: uppercase;
    `
      case 'medium':
        return `
      font-size: ${theme.sizeByFactor(2.2)};
      text-transform: uppercase;
    `
      case 'small':
      default:
        return `
      font-size: ${theme.sizeByFactor(2)};
    `
    }
  }}
`

const CarModelLine = styled.span`
  grid-area: carModel;
  ${SharedLineStyles}
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.1;

  ${({ theme, $size }) => {
    /**
     * Using switch statement to make this component
     * easier to extend.
     */
    switch ($size) {
      case 'large':
        return `
      font-size: ${theme.sizeByFactor(4)};
    `
      case 'medium':
      case 'small':
      default:
        return `
      font-size: ${theme.sizeByFactor(2)};
      `
    }
  }}
`

const CarTypeLine = styled.span`
  ${SharedLineStyles}
  grid-area: carType;
  line-height: 1.1;

  ${({ theme, $size }) => {
    /**
     * Using switch statement to make this component
     * easier to extend.
     */
    switch ($size) {
      case 'large':
        return `
      font-size: ${theme.sizeByFactor(2.5)};
      font-weight: 300;
    `
      case 'medium':
        return `
      font-size: ${theme.sizeByFactor(2)};
      font-weight: 300;
    `
      case 'small':
      default:
        return `
      font-size: ${theme.baseFontSize};
      font-weight: 300;
    `
    }
  }}
`

const StyledFieldNotMappedTooltip = styled(FieldNotMappedTooltip)`
  margin-left: ${({ theme }) => theme.sizings.lvl1};
`

const CarBrandAndModel = ({
  carBrand,
  carBrandIsNotMapped,
  carModel,
  carModelIsNotMapped,
  carType,
  size,
  className,
  layoutType,
  ...restProps
}) => {
  const { t } = useTranslation()

  return (
    <CarBrandAndModelContainer
      className={className}
      {...restProps}
      $layoutType={layoutType}
    >
      <CarBrandLine $size={size} data-test-e2e="brand">
        {carBrand}
        {carBrandIsNotMapped && (
          <StyledFieldNotMappedTooltip
            fieldName={t('brand').toLowerCase()}
            data-test-e2e="tooltip-brand-not-mapped"
          />
        )}
      </CarBrandLine>
      <CarModelLine $size={size} data-test-e2e="model">
        {carModel}
        {carModelIsNotMapped && (
          <StyledFieldNotMappedTooltip
            fieldName={t('model').toLowerCase()}
            data-test-e2e="tooltip-model-not-mapped"
          />
        )}
      </CarModelLine>
      {carType && (
        <CarTypeLine $size={size} data-test-e2e="type">
          {carType}
        </CarTypeLine>
      )}
    </CarBrandAndModelContainer>
  )
}

CarBrandAndModel.propTypes = {
  carBrand: PropTypes.string,
  carBrandIsNotMapped: PropTypes.bool,
  carModel: PropTypes.string,
  carModelIsNotMapped: PropTypes.bool,
  carType: PropTypes.string,
  size: PropTypes.oneOf([
    'small', // for display in tables, lists and other content
    'large', // for block overviews
  ]),
  className: PropTypes.string,
  layoutType: PropTypes.oneOf(Object.values(CarBrandAndModelLayoutTypes)),
}

CarBrandAndModel.defaultProps = {
  carType: null,
  carModel: null,
  size: 'small', // for display in tables, lists and other content
  className: '',
  layoutType: CarBrandAndModelLayoutTypes.COLUMN,
}

export default CarBrandAndModel
